import './styles.css'
import metalon from '../../../imgs/metalon01.png'
import perfil from '../../../imgs/perfil01.png'
import telha from '../../../imgs/telha01.png'
import plasma from '../../../imgs/plasma02.jpg'
import ferramentas from '../../../imgs/ferramentas.jpg'
import gases from '../../../imgs/gases.png'
import fibrocimento from '../../../imgs/fibrocimento.jpg'

const Products = () => {
	const products = [
		{name: 'Metalon', img: metalon, link: '/metalon'},
		{name: 'Perfil', img: perfil, link: '/perfil'},
		{name: 'Corte de Plasma', img: plasma, link: '/plasma'},
		{name: 'Máquinas e Ferramentas', img: ferramentas, link: '/plasma'},
		{name: 'Gases Industriais', img: gases, link: 'https://whatsa.me/5561998601528/?t=Ol%C3%A1!%20Gostaria%20de%20fazer%20um%20or%C3%A7amento.'},
		{name: 'Telhas Metálicas', img: telha, link: '/telhas'},
		{
			name: 'Telhas de Fibrocimento',
			img: fibrocimento,
			link: '/telhas',
		},
	]
	return (
		<div id='products'>
			<h1 className='title'>Nossos Produtos</h1>
			<div className='container'>
				{products.map((product) => (
					<div className='productContainer'>
						<span className='productTitle'>
							<a
								className='titleLink'
								href={
									product.link
								}
							>
								{product.name}
							</a>
						</span>
						<img
							className='productImage'
							src={product.img}
						/>
						<a
							className='productLink'
							href={product.link}
						>
							<span>Saiba Mais</span>
						</a>
					</div>
				))}
			</div>
			<a
				className='whatsLink'
				target='_blank'
				href='https://whatsa.me/5561998601528/?t=Ol%C3%A1!%20Gostaria%20de%20fazer%20um%20or%C3%A7amento.'
			>
				<div className='btn btnProducts'>
					Faça um orçamento
				</div>
			</a>
		</div>
	)
}
export default Products
