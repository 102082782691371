import './styles.css'
import logo from '../../../imgs/logo.png'

const Header = () => {
    return(
    <header id='header'>
        <img src={logo} className='minilogo'/>
        <div className='topBar'>
            <a className='pageLink' href='/'>Página Inicial</a>
            <a className='pageLink' href='/metalon'>Metalon</a>
            <a className='pageLink' href='/perfil'>Perfil</a>
            <a className='pageLink' href='/plasma'>Corte Plasma</a>
            <a className='pageLink' href='/telhas'>Telhas</a>
        </div>
    </header>
    )
}

export default Header