import './styles.css'
import logo from '../../../imgs/logo.png'
import whats from '../../../imgs/whatsapp.png'


const Home = () => {
    return(
        <div id="home">
            <div className='gradient'>
            </div>
            <div className='logoContainer'>
                <img className='logo' src={logo}/>
                <span className='slogan2'>Garantia de desempenho superior!</span>
                <span className='slogan1'>Metalon, Perfil, Telha, Corte Plasma e outros</span>
                <a className='whatsLink' href='#products' >
                    <div className='btn'>Confira Nossos Produtos</div>
                </a>
            </div>
            <a className='whatsLink' target='_blank' href='https://whatsa.me/5561998601528/?t=Ol%C3%A1!%20Gostaria%20de%20fazer%20um%20or%C3%A7amento.' >
                <img className='fab' src={whats} />
            </a>
        </div>
    )
}

export default Home