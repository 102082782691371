import './styles.css'
import Card from "../components/card";
import CtaButton from "../components/ctaButton";
import Footer from "../LandingPage/footer";
import CarouselComponent from "../components/carousel";
import Header from "../components/header"

import fibrocimento from '../../imgs/fibrocimento.jpg'
import telha from '../../imgs/telha01.png'

import {VscTools} from 'react-icons/vsc';
import {FaTemperatureLow} from 'react-icons/fa';
import {ImHeadphones} from 'react-icons/im';
import {AiOutlineFire} from 'react-icons/ai';
import {FiTool} from 'react-icons/fi';



const cards = [
    {title: 'Durabilidade', text: 'São conhecidas por sua longa vida útil, resistindo bem a condições climáticas adversas e a ação do tempo',icon: (<VscTools size={30} color='black'/>)},
    {title: 'Isolamento térmico.', text: 'Ajudam a manter a temperatura interna do imóvel mais agradável, reduzindo a necessidade de ar condicionado e outros equipamentos de refrigeração', icon: (<FaTemperatureLow size={30} color='black'/>)},
    {title: 'Isolamento acústico', text: 'As telhas de fibrocimento também ajudam a reduzir o ruído externo, proporcionando mais conforto acústico para os ocupantes do imóvel', icon: (<ImHeadphones size={30} color='black'/>)},
    {title: 'Resistência a fogo e umidade', text: 'São resistentes a fogo e umidade, tornando-se uma opção segura e durável para a cobertura de qualquer tipo de construção', icon: (<AiOutlineFire size={30} color='black'/>)},
    {title: 'Fácil instalação', text: 'As telhas de fibrocimento podem ser instaladas de maneira simples e rápida, reduzindo o tempo e o custo da obra', icon: (<FiTool size={30} color='black'/>)},

]

const galery = [fibrocimento]
const Telha = () => {
    return (
        <div id='telha'>
            <Header/>
            <h1 className="title">Telhas de Fibrocimento</h1>
            <p className="copy">As telhas de fibrocimento são uma opção popular e durável para a 
            obertura de construções residenciais, comerciais e industriais. Elas são feitas a 
            partir de uma mistura de cimento, fibras naturais e água, resultando em um material 
            resistente, leve e fácil de manusear. As telhas de fibrocimento também são conhecidas 
            por sua capacidade de isolar o calor e o som, além de serem resistentes a fogo, 
            umidade e cupins.
            </p>
           <CarouselComponent galery={galery} />

            <div className="cardsContainer">
                {cards.map((card) => (
                    <Card card={card} />
                ))}
            </div>
            <CtaButton/>
            <Footer/>
        </div>
    )
}
export default Telha