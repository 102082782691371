import './styles.css'

const Card = (props) => {
    const card = props.card
return(
    <div className="card">
        {card.icon}
        <span className="cardTitle">{card.title}</span>
        <span className="cardText">{card.text}</span>
    </div>
)
}

export default Card