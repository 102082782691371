import Header from "../components/header"
import {BsShieldCheck} from 'react-icons/bs';
import {BiHourglass} from 'react-icons/bi';
import {HiOutlineWrenchScrewdriver} from 'react-icons/hi2';
import {BiShapeTriangle} from 'react-icons/bi';
import {FiScissors} from 'react-icons/fi';
import Card from "../components/card";
import './styles.css'
import CarouselComponent from "../components/carousel";
import perfil01 from '../../imgs/perfil01.png'
import perfil02 from '../../imgs/perfil02.png'
import CtaButton from "../components/ctaButton";
import Footer from "../LandingPage/footer";



const cards = [
    {title: 'Resistência.', text: 'É altamente resistente, graças aos reforços em suas abas que proporcionam maior suporte estrutural', icon: (<BsShieldCheck size={30} color='black'/>)},
    {title: 'Versatilidade', text: 'Pode ser utilizado em diferentes aplicações de construção, como estruturas de sustentação, máquinas industriais e implementos agrícolas', icon: (<HiOutlineWrenchScrewdriver size={30} color='black'/>)},
    {title: 'Durabilidade', text: 'Produzido em aço carbono de alta qualidade, o Perfil U enrijecido é resistente à corrosão e possui longa vida útil',icon: (<BiHourglass size={30} color='black'/>)},
    {title: 'Facilidade de corte e montagem.', text: 'Pode ser facilmente cortado e adaptado às necessidades de cada projeto de construção, tornando a sua montagem mais fácil e rápida', icon: (<FiScissors size={30} color='black'/>)},
    {title: 'Estabilidade', text: ' Graças ao seu formato em "U" e aos reforços em suas abas, o Perfil U enrijecido possui grande estabilidade e resistência à flexão', icon: (<BiShapeTriangle size={30} color='black'/>)},

]

const galery = [perfil01, perfil02]
const Perfil = () => {
    return (
        <div id='perfil'>
            <Header/>
            <h1 className="title">Perfil</h1>
            <p className="copy">O Perfil U enrijecido é produzido em aço carbono e pode ser 
            encontrado em diversas espessuras e comprimentos, permitindo a sua utilização em 
            diferentes aplicações de construção, como estruturas de sustentação, implementos 
            agrícolas, máquinas industriais, entre outros.
            Com sua capacidade de suportar cargas pesadas e resistência à flexão, 
            o Perfil U enrijecido é uma opção segura e durável para projetos de construção que 
            exigem maior resistência estrutural.
            </p>
           <CarouselComponent galery={galery} />

            <div className="cardsContainer">
                {cards.map((card) => (
                    <Card card={card} />
                ))}
            </div>
            <CtaButton/>
            <Footer/>
        </div>
    )
}
export default Perfil