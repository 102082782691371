import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom'
import App from './App'
import Metalon from './pages/metalon'
import Perfil from './pages/perfil'
import Telha from './pages/telha'
import Plasma from './pages/plasma'

const AppRoutes = () => {
	return (
		<Router>
			<Routes>
				<Route exact path='/*' element={<App />} />
				<Route
					exact
					path='/metalon'
					element={<Metalon />}
				/>
				<Route
					exact
					path='/perfil'
					element={<Perfil />}
				/>
				<Route
					exact
					path='/telhas'
					element={<Telha />}
				/>
				<Route
					exact
					path='/plasma'
					element={<Plasma />}
				/>
			</Routes>
		</Router>
	)
}

export default AppRoutes
