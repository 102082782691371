import './styles.css'

const About = () => {
    return(
        <div id='about'>
            <div className='gradient'>
                .                
            </div>
            <h1 className='title'>QUEM SOMOS</h1>
            <div className='container'>
                <p className='text'>
                A empresa METALMIX foi fundada meados de 2001 com o nome de Albernaz, com a finalidade de desenvolver peças e máquinas diferenciadas.
                Fabricando máquinas no seguimento de frigorifico,equipamentos de pintura asfaltica, perfuração de poços artesiano e equipamentos para parque de diversão, sendo que a maioria se encontra em bom estado conservação. Entretanto com carência em equipamentos para aviario resolvemos investir nesta ideia, desenvolvendo forno com maior
                desempenho no mercado, graças ao trabalho serio e honesto garantimos um desempenho superior. 
                    <span className='detail'>
                        <br/><br/>Quer fazer parte desse grupo de clientes satisfeitos?<br/><br/>
                        Peça um orçamento sem compromisso.
                    </span>
                </p>
                <a className='whatsLink' target='_blank' href='https://whatsa.me/5561998601528/?t=Ol%C3%A1!%20Gostaria%20de%20fazer%20um%20or%C3%A7amento.' >
                    <div className='btn btnAbout'>Mandar Mensagem</div>
                </a>
            </div>
        </div>
    )
}

export default About