import Header from "./pages/components/header";
import About from "./pages/LandingPage/about";
import Footer from "./pages/LandingPage/footer";
import Home from "./pages/LandingPage/home";
import Products from "./pages/LandingPage/products";

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <About />
      <Products />
      <Footer />
    </div>
  );
}

export default App;
