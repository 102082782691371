import './styles.css'
import Carousel from 'nuka-carousel/lib/carousel'

const CarouselComponent = (props) => {
    const galery = props.galery
    return(
        <div className='imgsContainer'>
        <Carousel cellAlign='center' animation='fade' autoplay={true} autoplayInterval={3000} wrapAround={true} className='carousel' defaultControlsConfig={{
            nextButtonText: <span className="rightArrow">{'>'}</span>,
            prevButtonText: <span className="leftArrow">{'<'}</span>,
          }}>
          {galery.map((image) =><img className="galeryImage" src={image}/> )}  
      </Carousel>

        </div>
    )
}

export default CarouselComponent