import './styles.css'
import logo from '../../../imgs/logo.png'
import insta from '../../../imgs/instagram.png'

const Footer = () => {
    return(
        <>
        <div id='footer'>
            <div className='address section'>
                <h1 className='sectionTitle'>Endereço</h1>
                <span className='text'>Rodovia DF 230 - Km 7,5</span>
                <span className='text'>Planaltina-DF</span>
                <span className='text'>CEP 73367412</span>
            </div>
            <div className='contact section'>
                <h1 className='sectionTitle'>Contato</h1>
                <a className='link' target='_blank' href='https://whatsa.me/5561998601528/?t=Ol%C3%A1!%20Gostaria%20de%20fazer%20um%20or%C3%A7amento.' >
                    <span  className='text'>Whatsapp: 61 9 9860-1528</span>
                </a>
                <a className='link' target='_blank' href='mailto:metalmixdf@gmail.com' >
                    <span  className='text'>Email: metalmixdf@gmail.com</span>
                </a>
                <a className='link' target='_blank' href='https://www.instagram.com/metalmix061' >
                    <span className='text'>Instagram: <img src={insta} className='instaLogo' /></span>
                </a>
            </div>
            <div>
                <img className='logo' src={logo} />
            </div>
        </div>
        <div className='lastLine'>® Metal Mix - 2023</div>
        </>
    )
}

export default Footer