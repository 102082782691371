import Header from "../components/header"
import {TfiRulerAlt2} from 'react-icons/tfi';
import {AiOutlineFieldTime} from 'react-icons/ai';
import {VscTools} from 'react-icons/vsc';
import {BsBrush} from 'react-icons/bs';
import {HiOutlinePuzzlePiece} from 'react-icons/hi2';
import Card from "../components/card";
import './styles.css'
import CarouselComponent from "../components/carousel";
import plasma01 from '../../imgs/plasma01.png'
import plasma02 from '../../imgs/plasma02.jpg'
import CtaButton from "../components/ctaButton";
import Footer from "../LandingPage/footer";



const cards = [
    {title: 'Precisão.', text: 'É um processo altamente preciso, capaz de cortar materiais com precisão milimétrica, garantindo cortes limpos e precisos', icon: (<TfiRulerAlt2 size={30} color='black'/>)},
    {title: 'Versatilidade', text: 'É capaz de cortar uma ampla variedade de materiais, incluindo aço carbono, aço inoxidável, alumínio, cobre e outros metais, além de plásticos e outros materiais isolantes', icon: (<VscTools size={30} color='black'/>)},
    {title: 'Rapidez', text: 'É um processo rápido e eficiente, capaz de cortar materiais com alta velocidade e precisão, o que reduz o tempo de produção e aumenta a produtividade',icon: (<AiOutlineFieldTime size={30} color='black'/>)},
    {title: 'Flexibilidade', text: 'Pode ser usado para cortar diferentes formatos e tamanhos de materiais, permitindo a criação de peças personalizadas para atender às necessidades de cada projeto', icon: (<HiOutlinePuzzlePiece size={30} color='black'/>)},
    {title: 'Acabamento de alta qualidade', text: 'Produz uma superfície de corte limpa e suave, minimizando a necessidade de acabamentos adicionais', icon: (<BsBrush size={30} color='black'/>)},

]

const galery = [plasma01, plasma02]
const Plasma = () => {
    return (
        <div id='plasma'>
            <Header/>
            <h1 className="title">Corte Plasma</h1>
            <p className="copy">O corte plasma é um processo de corte térmico que utiliza um 
            jato de plasma para cortar materiais condutores de eletricidade, como metais.  
            O corte plasma é rápido, preciso e capaz de cortar metais com diferentes espessuras, 
            produzindo uma superfície de corte limpa e suave. É amplamente utilizado em diversas 
            indústrias, como a metalúrgica, aeroespacial e automotiva.
            </p>
           <CarouselComponent galery={galery} />

            <div className="cardsContainer">
                {cards.map((card) => (
                    <Card card={card} />
                ))}
            </div>
            <CtaButton/>
            <Footer/>
        </div>
    )
}
export default Plasma