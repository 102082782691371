import Header from "../components/header"
import {BsShieldCheck} from 'react-icons/bs';
import {BiHourglass} from 'react-icons/bi';
import {HiOutlineWrenchScrewdriver} from 'react-icons/hi2';
import {GiAutoRepair} from 'react-icons/gi';
import {MdAttachMoney} from 'react-icons/md';
import Card from "../components/card";
import './styles.css'
import CarouselComponent from "../components/carousel";
import metalon01 from '../../imgs/metalon01.png'
import metalon02 from '../../imgs/metalon02.png'
import CtaButton from "../components/ctaButton";
import Footer from "../LandingPage/footer";



const cards = [
    {title: 'Resistência.', text: 'Material altamente resistente para suportar cargas e condições adversas', icon: (<BsShieldCheck size={30} color='black'/>)},
    {title: 'Durabilidade.', text: 'Material durável, resistente à corrosão e ao desgaste', icon: (<BiHourglass size={30} color='black'/>)},
    {title: 'Versatilidade', text: 'Material versátil que pode ser facilmente cortado, dobrado e soldado', icon: (<HiOutlineWrenchScrewdriver size={30} color='black'/>)},
    {title: 'Fácil manutenção', text: 'Material de baixa manutenção, requerendo pouco cuidado', icon: (<GiAutoRepair size={30} color='black'/>)},
    {title: 'Custo-benefício', text: 'Material acessível e com bom custo-benefício a longo prazo',icon: (<MdAttachMoney size={30} color='black'/>)},

]

const galery = [metalon01, metalon02]
const Metalon = () => {
    return (
        <div id='metalon'>
            <Header/>
            <h1 className="title">METALON</h1>
            <p className="copy">O Metalon é um material resistente, durável, 
            versátil, de baixa manutenção e com um bom custo-benefício. 
            Essas vantagens o tornam uma escolha popular para diversos tipos de 
            projetos de construção, desde estruturas simples até projetos mais 
            complexos e sofisticados.
            </p>
           <CarouselComponent galery={galery} />

            <div className="cardsContainer">
                {cards.map((card) => (
                    <Card card={card} />
                ))}
            </div>
            <CtaButton/>
            <Footer/>
        </div>
    )
}
export default Metalon